<template>
    <button type="button"
        class="group z-[60] flex w-full items-center space-x-md rounded-2xs border px-md py-sm
            text-left text-base font-light shadow-lg outline-none"
        :class="{
            'border-gray-100 bg-white active:bg-green-100': toast.theme === 'success',
            'border-yellow-300 bg-yellow-50 active:bg-yellow-100': toast.theme === 'warning',
            'border-red-300 bg-red-50 active:bg-red-100': toast.theme === 'danger',
        }"
        @click="remove">
        <span class="flex size-10 shrink-0 items-center justify-center rounded-md px-1 text-2xl text-white"
            :class="{
                'bg-green-500 group-hover:bg-green-600': toast.theme === 'success',
                'bg-yellow-500 group-hover:bg-yellow-600': toast.theme === 'warning',
                'bg-red-500 group-hover:bg-red-600': toast.theme === 'danger',
            }">
            <v-icon :icon="icon" />
        </span>

        <span class="text-xl">
            {{ toast.text }}
        </span>
    </button>
</template>

<script lang="ts" setup>
import type { Icon, Toast } from '~~/types';
import { useToast } from '~~/store/toast';

const properties = defineProps<{
    toast: Toast
}>();

const icon = computed<Icon>(() => {
    if (properties.toast.theme === 'info') {
        return 'info-circle';
    }

    if (properties.toast.theme === 'success') {
        return 'check';
    }

    if (properties.toast.theme === 'warning') {
        return 'warning';
    }

    if (properties.toast.theme === 'danger') {
        return 'info-circle';
    }

    throw new Error('Unknown theme');
});

const toastStore = useToast();

function remove() {
    toastStore.remove(properties.toast.id);
}
</script>
