export function useDefaultDynamicHead() {
    const config = useRuntimeConfig();
    const route = useRoute();

    // useHead merges its parameters into the current head so it can be called multiple times.
    useHead(() => ({
        link: [
            {
                rel: 'canonical',
                href: `${config.public.appUrl + route.fullPath}`,
            },
        ],
    }));
}
