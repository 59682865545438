<template>
    <div class="fixed bottom-10 right-0 z-[70] w-screen space-y-md overflow-visible px-6 sm:w-96">
        <transition-group
            enter-active-class="transition-all duration-500"
            leave-active-class="transition-all duration-500 absolute"
            move-class="transition-all duration-500"
            enter-from-class="opacity-0 -translate-y-full"
            leave-to-class="opacity-0 translate-y-full"
        >
            <v-toast v-for="item in toast.items"
                :key="item.id"
                :toast="item" />
        </transition-group>
    </div>
</template>

<script lang="ts" setup>
import { useToast } from '~~/store/toast';

const toast = useToast();
</script>
